/*
    Taken from: https://www.joshwcomeau.com/css/custom-css-reset/
*/
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
  padding: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
    Taken from: https://andy-bell.co.uk/a-modern-css-reset/
*/
/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/*
    Taken from: https://meyerweb.com/eric/tools/css/reset/
*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*
    Jus because
*/
:root {
  scrollbar-gutter: stable;
}
@charset "UTF-8";
:root {
  --svg-height: 600px;
  --padding-horizontal: 1rem;
  --container-sticky-top: 8rem;
  --mask: linear-gradient(to bottom,
          rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 10%,
          rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x;
  --nav-height: 4.31rem;
}

@media (min-width: 480px) {
  :root {
    --container-sticky-top: 7rem;
  }
}
nav {
  width: 100%;
  padding: 0 var(--padding-horizontal);
  position: sticky;
  top: 0;
  height: var(--nav-height);
  display: flex;
  flex-direction: row;
  align-content: center;
}
nav::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  height: calc(var(--nav-height) - 2rem);
  margin-top: var(--nav-height);
}
nav ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
nav ul li.main {
  padding: 0.05rem 1rem;
}

#main.splash h2:first-of-type {
  margin-top: 5.65rem;
}
#main.splash h2 {
  margin-top: 4.464rem;
}
#main.splash header {
  grid-column: 1/-1;
  max-width: 100%;
  justify-self: center;
}
#main.splash header h1 {
  max-width: 30.4rem;
  margin: 0 auto;
}
#main.splash header hgroup h1:first-of-type {
  margin-top: calc(-1 * clamp(1rem, 10vw - 1rem, 2rem));
}
#main.splash header p.top {
  justify-self: center;
  text-align: center;
  padding: 0;
}

div.pair {
  display: flex;
  flex-direction: row;
  width: min(100% - var(--padding-horizontal) * 2, 38.952rem);
  grid-column: 1/-1;
  justify-content: center;
  -moz-column-gap: 1.15rem;
       column-gap: 1.15rem;
  align-items: flex-start;
  margin-top: 1.73rem;
}

.mini-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.384rem;
  max-width: 18.215rem;
}
.mini-card .preview {
  width: 100%;
  aspect-ratio: 16/9;
}
.mini-card .preview img {
  margin: 0;
}
.mini-card .info {
  margin-top: 0;
  margin-bottom: 0;
  flex: 1;
}
.mini-card p {
  padding: 0;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 0.65rem;
       column-gap: 0.65rem;
}
.card .preview {
  flex: 60%;
  aspect-ratio: 16/9;
}
.card .preview img {
  margin: 0;
}
.card .info {
  flex: 40%;
}
.card .info p {
  padding: 0;
  margin: 0;
}
.card .info h4 {
  margin-top: 0.39rem;
  margin-bottom: 0;
}

div.preview {
  position: relative;
}
div.preview:has(img.coming_soon)::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/*
a {
    &.card-wrapper {
        &.card-wrapper_mini {
            //width: clamp(10rem, 15%, 20rem);
            //width: clamp(12rem, 100%, 15rem);
        }

    }
}
*/
@media only screen and (max-width: 480px) {
  .card {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: flex-start;
  }
  .card .preview {
    flex: unset;
    width: 100%;
  }
  .card .info {
    flex: unset;
    width: 100%;
  }
  .card .info h4 {
    margin-top: 0;
  }
  div.pair {
    grid-column: 2/3;
    flex-direction: column;
    width: 100%;
    row-gap: 3rem;
  }
  article div[data-controllers=story-2-4], article div[data-controllers=story-2-5] {
    flex-direction: column;
    grid-column: 2;
  }
}
@media only screen and (max-width: 480px) {
  .mini-card {
    max-width: none;
  }
}
/*
@media only screen and (max-width: 640px) {
    a {
        &.card-wrapper {
            &.card-wrapper_mini {
                width: clamp(12rem, 100%, 15rem);
            }

            .mini-card {
                width: 100%;
            }
        }
    }
}
    */
#interactivo {
  height: auto;
  aspect-ratio: 16/9;
}

article {
  display: grid;
  grid-template-columns: 1fr min(21.64rem, 100% - var(--padding-horizontal) * 2) 1fr;
  grid-column-gap: 0;
  justify-items: center;
}
article > * {
  grid-column: 2;
}
article > .scrolly,
article #interactivo {
  width: 100%;
  width: min(100%, 38.952rem);
  grid-column: 1/-1;
}
article [data-container] {
  width: 100%;
}
article [data-container=story-1],
article [data-container=story-2-3],
article [data-container=story-2-4],
article [data-container=story-2-4],
article [data-container=story-2-5],
article [data-controllers=story-2-4],
article [data-controllers=story-2-5],
article [data-container=story-3-1],
article [data-container=story-3-2],
article [data-container=story-3-4] {
  width: min(100%, 38.952rem);
  grid-column: 1/-1;
  padding: 0 var(--padding-horizontal);
}
article [data-container=story-2-3] {
  margin-bottom: 0.615rem;
}
article [data-controllers=story-2-4],
article [data-controllers=story-2-5] {
  width: 100%;
}
article [data-container=story-2-4-labels] {
  width: min(100%, 28.952rem);
  grid-column: 1/-1;
  padding: 0 var(--padding-horizontal);
  margin: 0.4rem 0;
}
article [data-container=story-2-4-labels] ul {
  list-style-type: none;
  margin: 0;
  font-size: 0.6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
article [data-container=story-2-4-labels] svg {
  display: inline-block;
  width: 0.5rem;
  aspect-ratio: 1/1;
  margin-right: 0.1rem;
}
article div.carrousel {
  width: 100%;
  justify-self: start;
  display: flex;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  flex-direction: row;
  padding-bottom: 1rem;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
article div.carrousel.carrousel__large {
  justify-self: start;
  scroll-snap-type: x mandatory;
  overflow: hidden;
}
article div.carrousel h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
article div.search, article div.dropdown {
  margin-bottom: 0.615rem;
}
article div.controllers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  font-size: 0.8rem;
}
article div.controllers div.control {
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 0.3rem;
       column-gap: 0.3rem;
}
article div.controllers div.control p {
  margin: 0;
  padding: 0;
}
article div.controllers div.control span.trend {
  width: 1.5rem;
}

.scrolly {
  display: grid;
  grid-template-columns: 1fr min(21.64rem, 100% - var(--padding-horizontal) * 2) 1fr;
  grid-column-gap: 0;
  justify-items: center;
}
.scrolly > * {
  grid-column: 2;
}
.scrolly > [data-container] {
  position: sticky;
  top: var(--container-sticky-top);
}
.scrolly > [data-container=story-2-1] div[data-clave], .scrolly > [data-container=story-3-2] div[data-clave], .scrolly > [data-container=story-3-3] div[data-clave], .scrolly > [data-container=story-3-4] div[data-clave] {
  width: 100%;
  scroll-snap-align: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.scrolly > [data-container=story-2-1] div[data-clave] svg, .scrolly > [data-container=story-3-2] div[data-clave] svg, .scrolly > [data-container=story-3-3] div[data-clave] svg, .scrolly > [data-container=story-3-4] div[data-clave] svg {
  /*width: 505.933px;*/
  max-width: none;
}
.scrolly > [data-container=story-3-3] div.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@media only screen and (min-width: 1024px) {
  article > .scrolly {
    width: max(100% - var(--padding-horizontal) * 2, 38.952rem);
    width: min(100% - var(--padding-horizontal) * 2, 53.77rem);
  }
  article > .scrolly > [data-container=story-2-1] {
    grid-column: 1/-1;
  }
  article > .scrolly > [data-container=story-2-1] div[data-clave] {
    width: calc((100% - 4rem) / 3);
    max-width: 100%;
    scroll-snap-align: none;
  }
  article > .scrolly > [data-container=story-2-1] div[data-clave] svg {
    max-width: 100%;
  }
  article div.carrousel {
    overflow-x: auto;
    justify-self: center;
    scroll-snap-type: none;
  }
}
article {
  margin-top: 3rem;
  margin-bottom: 3rem;
  /*
  div[data-container] {
  }
  */
}
article header {
  text-align: center;
  padding: 0.39rem;
  margin-bottom: 3.617rem;
}
article header p.top {
  justify-self: center;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
article header p.bottom {
  justify-self: center;
  text-align: center;
  margin-top: 0.39rem;
  margin-bottom: 0;
}
article h1,
article h2 {
  margin-top: 0.39rem;
}
article p {
  margin-top: 0.66rem;
  margin-bottom: 0.66rem;
  padding: 0.5rem;
  justify-self: start;
}
article p[data-capas], article p.pseudocapas {
  padding: 1.5rem;
  margin-top: var(--svg-height);
}
article p[data-capas] img, article p.pseudocapas img {
  width: 1.5rem;
  top: 0.2rem;
  /*
  display: inline-block;
  aspect-ratio: 1/1;
  position: relative;
  */
}
article p[data-capas].light img, article p.pseudocapas.light img {
  width: 1rem;
  top: 0.1rem;
}
article p.disclaimer {
  text-align: center;
  margin-bottom: 1rem;
}
article p img {
  display: inline-block;
  width: 1rem;
  aspect-ratio: 1/1;
  position: relative;
  top: 0.2rem;
}
article div.scrolly {
  margin-bottom: 1.34rem;
  /*
  p[data-capas]:nth-of-type(-n+2) {
      margin-top: initial;
  }
      */
}
article div.scrolly p[data-capas=positive] {
  margin-top: 2rem;
}
article div.scrolly p[data-capas]:first-of-type {
  margin-top: 8rem;
}
article div.scrolly p[data-capas]:last-of-type {
  margin-bottom: var(--svg-height);
}
article h4 {
  margin-top: 4rem;
  margin-bottom: 0.48rem;
}
article ul {
  margin-bottom: 2em;
  padding-left: 1rem;
}
article ul.links li a::after {
  background-size: 0.5rem 0.8rem;
  display: inline-block;
  width: 0.5rem;
  height: 0.8rem;
  margin-left: 0.2rem;
}
article ul.credits li ul {
  margin-top: initial;
  margin-bottom: initial;
  padding-top: initial;
  padding-bottom: initial;
}
article hr {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
  width: min(10.94rem, 100%);
}
article figure {
  margin-top: 3rem;
  margin-bottom: 2.2rem;
}
article figure img {
  margin-bottom: 1.1rem;
}
article ol {
  padding: 0.5rem;
}
article ol.footnotes {
  margin-top: 4rem;
}
article ol.footnotes li {
  margin-bottom: 1rem;
}
article ol.footnotes li:last-of-type {
  margin-bottom: 0;
}
article ol.footnotes li::before {
  display: inline-block;
  vertical-align: super;
  margin-right: 0.2rem;
}
article > [data-container=story-3-4] div.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.modal .modal__overlay {
  width: 100%;
}
.modal .modal__header {
  justify-content: flex-end;
  display: none;
}
.modal .modal__container {
  max-width: max(15rem, 50%);
  padding: clamp(1.5rem, 6.6667vw, 3rem);
}
.modal .modal__content {
  margin-top: 0;
  margin-bottom: 0;
}
.modal.is-open {
  position: relative;
}
.modal main {
  width: 100%;
  height: 70vh;
}
.modal h2 {
  margin-top: 2rem;
}
.modal h2.about-top {
  margin-top: 0;
}
.modal p {
  margin-top: 1.32rem;
}
.modal p.cc {
  display: block;
}
.modal a.cc img {
  max-width: 20%;
}
.modal h2 + p {
  margin-top: 0;
}
.modal ul.credits {
  display: block;
}
.modal ul.credits li ul {
  margin-top: initial;
  margin-bottom: initial;
  padding-top: initial;
  padding-bottom: initial;
}
.modal ul.credits li ul li {
  margin-top: 0;
}

#modal-stories .modal__overlay {
  width: 100%;
  align-items: baseline;
}
#modal-stories .modal__container {
  width: 100%;
  max-width: 100%;
  padding: 1rem;
  margin-top: var(--nav-height);
}
#modal-stories .modal__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
#modal-stories main {
  height: auto;
}

@media only screen and (max-width: 640px) {
  #modal-stories .modal__content {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
}
/*
NOTE: Los valores en rem del sitio de europa se dividen entre 1.5384615384615384615384615384615 para que cuadren con el valor font-size de root
No son exactos porque usamos tamaño ajustable al dispositivo y no un valor fijo
*/
header.brand div.band {
  width: 100%;
  padding: 0 0 0 16px;
  position: relative;
  min-height: 28px;
}
header.brand div.band p,
header.brand div.band button {
  display: inline-block;
}
header.brand div.band button {
  margin: 0 0 0 6px;
  padding: 0 7px;
  position: relative;
  display: inline-block;
  height: 28px;
  min-height: 28px;
}
header.brand div.band button::after {
  display: inline-block;
  width: 20px;
  height: 8px;
  margin-left: 2px;
}
header.brand div.band div.content > p:first-of-type::before, header.brand div.band div.content > p:nth-of-type(2)::before {
  content: "";
  width: 24px;
  height: 16px;
  float: left;
  margin: 5.5px 8px 0 0;
}
header.brand div.band div.content > p:nth-of-type(2) {
  display: none;
}
header.brand div.band div.content {
  display: inline-block;
  position: relative;
}
header.brand div.banner {
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 0.325rem;
  padding-top: 0.325rem;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
}
header.brand div.banner img {
  display: block;
  max-height: 1.21875rem;
  max-width: 100%;
}
header.brand div.info {
  margin: 0 auto;
  display: flex;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
}
header.brand div.info div.content {
  align-self: center;
  color: #515560;
  flex-grow: 1;
  padding: 0.65rem 0;
}
header.brand div.info div.content::before {
  content: "";
  position: relative;
  top: -0.65rem;
  width: 100%;
  height: 0;
  display: block;
}
header.brand div.info div.content div.wrapper {
  display: flex;
  gap: 0.4875rem;
  margin-top: 0.24375rem;
  align-items: center;
}
header.brand div.info div.content div.wrapper p:first-of-type {
  padding: 0.1625rem 0.4875rem;
  align-self: center;
}
header.brand div.info div.content div.wrapper p:nth-of-type(2) {
  display: none;
}
header.brand #dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  padding: 12px 16px;
  margin: 0;
}
header.brand #dropdown[hidden] {
  display: none;
}

@media (max-width: 820px) {
  header.brand div.band div.content > p:nth-of-type(2) {
    display: inline;
  }
  header.brand div.band div.content > p:first-of-type {
    display: none;
  }
}
@media (min-width: 480px) {
  header.brand div.banner {
    max-width: 100%;
    width: 480px;
  }
  header.brand div.banner img {
    height: 1.625rem;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
  header.brand div.info {
    max-width: 100%;
    width: 480px;
  }
  footer.brand div.wrapper {
    max-width: 100%;
    width: 480px;
  }
}
@media (min-width: 768px) {
  header.brand div.banner, header.brand div.info {
    max-width: 100%;
    width: 768px;
  }
  footer.brand div.wrapper {
    max-width: 100%;
    width: 768px;
  }
}
@media (min-width: 996px) {
  header.brand div.banner {
    max-width: 100%;
    width: 996px;
    flex-direction: row;
    padding-bottom: 0.975rem;
    padding-top: 0.975rem;
  }
  header.brand div.banner img {
    display: block;
    height: 1.7875rem;
    width: auto;
  }
  header.brand div.info {
    max-width: 100%;
    width: 996px;
  }
  header.brand div.info div.content div.wrapper p:nth-of-type(2) {
    display: inline;
  }
  footer.brand div.wrapper {
    padding: 2.6rem 0.65rem;
    max-width: 100%;
    width: 996px;
  }
  footer.brand div.wrapper div.row {
    display: grid;
    grid-gap: 0 1.3rem;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 1.95rem;
  }
  footer.brand li#longlink {
    display: inline;
  }
  footer.brand #link {
    display: none;
  }
}
@media (min-width: 1140px) {
  header.brand div.banner, header.brand div.info {
    max-width: 100%;
    width: 1140px;
  }
  footer.brand div.wrapper {
    max-width: 100%;
    width: 1140px;
  }
}
footer.brand {
  /*
  display: flex;
  margin: 0;
  */
  color: #171a22;
}
footer.brand div.wrapper {
  margin: 0 auto;
  padding: 2.6rem 0.65rem;
}
footer.brand div.wrapper div.row {
  padding-bottom: 1.95rem;
}
footer.brand div.wrapper div.row:last-child {
  padding-bottom: 0;
}
footer.brand div.wrapper div.row:first-child {
  padding-top: 0;
}
footer.brand div.wrapper div.row div.column div.section {
  padding-top: 0.975rem;
}
footer.brand div.wrapper div.row div.column div.section div.description {
  margin-bottom: 0.325rem;
  margin-top: 0.65rem;
}
footer.brand h2 {
  padding-bottom: 0.4875rem;
}
footer.brand ul {
  margin-bottom: 0;
  margin-top: 0.4875rem;
}
footer.brand ul.inline {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}
footer.brand ul.inline li {
  margin-right: 0.65rem;
}
footer.brand ul.inline li a {
  display: flex;
  align-items: center;
}
footer.brand ul li {
  margin-top: 0.325rem;
}
footer.brand a {
  display: block;
}
footer.brand a.logo img {
  display: block;
  height: 1.7875rem;
  max-width: 100%;
}
footer.brand p a {
  display: inline;
}
footer.brand svg {
  margin-inline-end: 0.325rem;
  position: relative;
  height: 0.8125rem;
  width: 0.8125rem;
}
footer.brand #longlink {
  display: none;
}
@charset "UTF-8";
.debug {
  outline: 1px solid red;
  background-color: #cdcdcd;
}

.hover:hover {
  text-decoration: underline;
  cursor: pointer;
}

nav {
  z-index: 4;
  background: #ffffff;
}
nav::after {
  background: #ffffff;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  pointer-events: none;
}
nav ul {
  list-style-type: none;
}
nav ul li.main {
  border: 1px solid black;
  border-radius: 1rem;
}
nav ul li a {
  color: #000000;
  text-decoration: none;
}

#main.splash article header {
  /*
  h1 {
      color: #E9E9E9;
  }
      */
}
#main.splash article header h1 {
  color: #175EEA;
}
#main.splash article header p.top {
  color: #8A8A8A;
}

.mini-card .preview img {
  border-radius: 34px;
}
.mini-card p.info {
  color: #175EEA;
  text-decoration: none;
  text-transform: uppercase;
}
.mini-card p.info:hover {
  text-decoration: underline;
}

.card .preview img {
  border-radius: 34px;
}
.card .info p {
  text-transform: uppercase;
  color: #175EEA;
  text-decoration: none;
}
.card .info p::after {
  content: " ➤";
}
.card .info p:hover {
  text-decoration: underline;
}

a.card-wrapper {
  text-decoration: none;
  color: #000000;
}

div.preview:has(img.coming_soon)::after {
  content: "Coming soon";
  text-transform: uppercase;
  color: #AFAFAF;
}

/*
footer {
    ul {
        list-style-type: none;
    }
}*/
#interactivo {
  z-index: 2;
}

article {
  scroll-snap-type: y mandatory;
}
article h1,
article h2,
article h3,
article h4,
article h5,
article h6 {
  background-color: rgba(255, 255, 255, 0.8);
}
article header p.top {
  text-transform: uppercase;
  color: #175EEA;
}
article header p.bottom {
  color: #828282;
}
article p {
  background-color: rgba(255, 255, 255, 0.8);
}
article p.intro {
  color: #777777;
}
article p[data-capas], article p.pseudostep {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.21);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
}
article p.disclaimer {
  color: #B0B0B0;
}
article figcaption {
  color: #555555;
}
article a {
  color: #175EEA;
}
article ul {
  background-color: rgba(255, 255, 255, 0.8);
  color: #616161;
}
article ul.links li {
  list-style-type: disc;
}
article ul.links li a {
  text-decoration: underline;
  color: #616161;
  /*
                  &:hover,
                  &:active {
                      text-decoration: underline;
                  }
  */
}
article ul.links li a::after {
  background-image: url("../img/icons/link.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
}
article hr {
  border: 1px solid #175EEA;
}
article sup {
  color: #175EEA;
}
article sup a {
  text-decoration: none;
}
article ol.footnotes {
  counter-reset: item;
  list-style: none;
  color: #777777;
}
article ol.footnotes li {
  scroll-margin-top: 6rem;
}
article ol.footnotes li::before {
  content: "(" counter(item) ") ";
  counter-increment: item;
  color: #175EEA;
}

[data-container] svg.ignore {
  pointer-events: none;
}
[data-container] svg .cover,
[data-container] svg .fondo,
[data-container] svg .ignore {
  pointer-events: none;
}
[data-container] svg .flag {
  cursor: pointer;
}
[data-container] svg .drag {
  cursor: grab;
}

[data-container=story-2-5] g.axisX > path {
  display: none;
}
[data-container=story-2-5] g.axisX g.tick line {
  stroke-dasharray: 5, 5;
  stroke: grey;
}
[data-container=story-2-5] g.axisX g.tick text {
  fill: grey;
  font-size: 14px;
}

[data-container=story-3-2] svg g.x g.tick text,
[data-container=story-3-2] svg g.y g.tick text,
[data-container=story-3-3] svg g.x g.tick text,
[data-container=story-3-3] svg g.y g.tick text,
[data-container=story-3-4] svg g.x g.tick text,
[data-container=story-3-4] svg g.y g.tick text {
  fill: grey;
}
[data-container=story-3-2] svg g.y g.tick:first-of-type,
[data-container=story-3-3] svg g.y g.tick:first-of-type,
[data-container=story-3-4] svg g.y g.tick:first-of-type {
  display: none;
}

.modal .modal__overlay {
  background: rgba(150, 177, 220, 0.425);
}
.modal .modal__container {
  box-shadow: 0px 0px 36.5px 12px rgba(0, 0, 0, 0.2509803922);
  border-radius: 1rem;
  overflow-y: hidden;
}
.modal.is-open {
  z-index: 4;
}
.modal h2 {
  color: #000000;
}
.modal ul {
  list-style-type: none;
}

#modal-about main {
  overflow-y: auto;
  scrollbar-width: thin;
}
#modal-about a {
  color: #175EEA;
}

#modal-stories .modal__overlay {
  background: rgba(255, 255, 255, 0);
}
#modal-stories .modal__container {
  box-shadow: none;
  border-radius: 0;
}

header.brand {
  border-bottom: solid 6px #f2718a;
}
header.brand div.band {
  background-color: #404040;
  color: #ffffff;
}
header.brand div.band button {
  text-decoration: none;
  background-color: #404040;
  color: #ffffff;
  cursor: pointer;
  border: 3px solid rgba(255, 255, 255, 0);
  border-style: none;
}
header.brand div.band button::after {
  background: center center/12px auto no-repeat rgba(255, 255, 255, 0) url(https://webtools.europa.eu/images/chevron-white.svg);
  content: "";
  transition: all 0.3s;
}
header.brand div.band button:hover::after, header.brand div.band button:active::after, header.brand div.band button:focus::after, header.brand div.band button[aria-expanded=true]::after {
  background-image: url(https://webtools.europa.eu/images/chevron-blue.svg);
}
header.brand div.band button:hover, header.brand div.band button:focus, header.brand div.band button:active, header.brand div.band button[aria-expanded=true] {
  background-color: #fff;
  color: #004494;
  outline: none;
}
header.brand div.band button:focus, header.brand div.band button:active {
  outline: none;
  border: 3px solid #ffd617;
}
header.brand div.band button[aria-expanded=true]::after {
  transform: rotate(-180deg);
}
header.brand div.band div.content > p:first-of-type::before, header.brand div.band div.content > p:nth-of-type(2)::before {
  background: center center/110% auto no-repeat #004494 url("../img/brand/flag.svg");
  border: 1px solid #7f99cc;
}
header.brand div.info div.content::before {
  border-top: 2px solid #cfdaf5;
}
header.brand div.info div.content div.wrapper p:first-of-type {
  background-color: #f4f5f5;
  border-left: solid 6px #f2718a;
}
header.brand #dropdown {
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.4);
  color: #444;
}
header.brand #dropdown a {
  text-decoration: none;
  color: #004494;
}
header.brand #dropdown a:hover {
  text-decoration: underline;
}

footer.brand {
  background-color: #f3f6fc;
  box-shadow: 0 3px 5px rgba(9, 49, 142, 0.04), 0 0 18px rgba(9, 49, 142, 0.04), 0 6px 10px rgba(9, 49, 142, 0.04), 0 -4px 4px rgba(9, 49, 142, 0.04);
  color: #171a22;
}
footer.brand div.wrapper div.row {
  border-bottom: 2px solid #cfdaf5;
}
footer.brand div.wrapper div.row:last-child {
  border-bottom: 0;
}
footer.brand a {
  text-decoration: none;
  color: #0e47cb;
  background-color: transparent;
  transition: color 0.3s ease-in-out;
}
footer.brand a:hover {
  color: #082b7a;
}
footer.brand a:focus-visible {
  text-decoration: underline;
  color: #082b7a;
  border-radius: 2px;
  outline: 2px solid #0e47cb;
  outline-offset: 2px;
}
footer.brand a:focus-visible:hover {
  text-decoration: none;
}
footer.brand a.logo img {
  border-style: none;
}
footer.brand h2 {
  border-bottom: 1px solid #cfdaf5;
}
footer.brand ul {
  list-style: none;
}
footer.brand svg {
  fill: currentcolor;
}
:root {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  /*
  font-size: 26px;
  line-height: 1.32;
  */
  font-size: clamp(1.25rem, 0.89vw + 1.055rem, 1.625rem);
  line-height: clamp(1.25rem, 2.125vw + 0.785rem, 2.145rem);
}

nav {
  font-size: 0.9rem;
}
nav ul li.main {
  font-weight: 700;
  font-size: 1.1rem;
}

#main.splash article h2 {
  line-height: 0.7;
  font-size: 2.54rem;
  font-weight: 400;
}
#main.splash article header {
  /*
                  h1 {
                      font-family: "IBM Plex Sans", sans-serif;
                      font-size: 10.46rem;
                      font-size: clamp(3.077rem, 30vw - 1rem, 10.462rem);
                      line-height: 0.8;
                      font-weight: 700;
                  }
  */
}
#main.splash article header h1 {
  font-family: "Instrument Serif", serif;
  font-size: 2.77rem;
  font-size: clamp(1.538rem, 6vw + 0.5rem, 2.769rem);
  line-height: 0.986;
  font-style: italic;
}
#main.splash article header p.top {
  font-size: 1.16rem;
  line-height: 2.36;
}

.mini-card p.info {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 0.923rem;
}
.mini-card h4.info {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-size: 1.23rem;
  line-height: 1.3125;
}

.card .info p {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-size: 0.81rem;
  line-height: 0.93;
}
.card .info h4 {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-size: 1.24rem;
  line-height: 1.1;
}

div.preview:has(img.coming_soon)::after {
  font-weight: 700;
  font-size: 1rem;
}

/*
footer {
    font-size: 0.9rem;
}
    */
article h1,
article h2,
article h3,
article h4,
article h5,
article h6 {
  font-family: "Instrument Serif", serif;
}
article header p.top {
  font-size: 0.89rem;
  line-height: 1.04;
  font-weight: 700;
}
article header p.bottom {
  font-size: 0.616rem;
  line-height: 1.5;
}
article header h1 {
  font-size: 3.16rem;
  font-weight: 400;
  line-height: 1.03;
}
article header h2 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-size: 1.08rem;
  line-height: 1.14;
}
article p.intro {
  font-size: 0.9rem;
}
article p[data-capas], article p.pseudostep {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-size: 1.43rem;
  line-height: 1.24;
}
article p[data-capas] strong, article p.pseudostep strong {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
}
article p[data-capas].light, article p.pseudostep.light {
  font-size: 1rem;
  font-family: "IBM Plex Sans", sans-serif;
}
article p[data-capas=highlight_lines_1] strong, article p[data-capas=highlight_lines_2] strong, article p[data-capas=highlight_lines_3] strong, article p[data-capas=highlight_lines_4] strong {
  font-family: "Instrument Serif", serif;
  font-weight: 700;
}
article p.disclaimer {
  font-weight: 400;
  font-size: 0.54rem;
  line-height: 0.65;
}
article figcaption {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 0.627rem;
  line-height: 1.35;
}
article h4 {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-size: 1.43rem;
  line-height: 1.24;
}
article ul {
  font-size: 0.77rem;
  line-height: 1.7;
}
article ul.credits li {
  font-weight: bold;
}
article ul.credits li ul li {
  font-weight: normal;
}
article sup {
  font-size: 0.6rem;
  font-weight: 700;
}
article ol.footnotes {
  font-size: 0.88rem;
  line-height: 1.48;
}
article ol.footnotes li::before {
  font-size: 0.6rem;
  font-weight: 700;
}
article div.search {
  font-size: 0.7rem;
  font-weight: 400;
}
article div.dropdown {
  font-size: 0.7rem;
  font-weight: 400;
}
article div.dropdown option {
  font-size: 0.7rem;
  font-weight: 400;
}

[data-container=story-3-3] svg g.x g.tick text,
[data-container=story-3-3] svg g.y g.tick text,
[data-container=story-3-4] svg g.x g.tick text,
[data-container=story-3-4] svg g.y g.tick text {
  font-size: 18px;
}

.modal {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1rem;
}
.modal h2 {
  font-family: "Instrument Serif", serif;
}
.modal ul.credits li {
  font-weight: normal;
  font-size: 0.8rem;
}
.modal ul.credits li ul li {
  font-weight: normal;
  font-size: 1rem;
}

@media (max-width: 480px) {
  .modal {
    font-size: 0.9rem;
  }
  .modal ul.credits li {
    font-size: 0.8rem;
  }
  .modal ul.credits li ul li {
    font-size: 0.9rem;
  }
}
header.brand {
  font-size: 14px;
  font-family: Arial, Verdana;
}
header.brand div.band {
  min-height: 28px;
  line-height: 28px;
}
header.brand div.band button {
  font-weight: 400;
  line-height: 1.15;
}
header.brand div.info div.content {
  font: normal normal 700 0.8125rem/1.1375rem arial, sans-serif;
}
header.brand div.info div.content div.wrapper p:nth-of-type(1) {
  font: normal normal 700 0.73125rem/0.975rem arial, sans-serif;
}
header.brand div.info div.content div.wrapper p:nth-of-type(2) {
  font: normal normal 700 0.65rem/0.975rem arial, sans-serif;
}
header.brand #dropdown p {
  line-height: 1.4;
}

@media (min-width: 996px) {
  header.brand div.info div.content {
    font: normal normal 700 1.3rem/1.625rem arial, sans-serif;
  }
}
footer.brand {
  font: normal normal 400 0.65rem/0.975rem arial, sans-serif;
}
footer.brand h2 {
  color: #171a22;
  font: normal normal 400 1.1375rem/1.3rem arial, sans-serif;
}
.autocomplete {
  background: white;
  z-index: 1000;
  font-size: 0.8rem;
  line-height: 1rem;
  overflow: auto;
  box-sizing: border-box;
  outline: 1px solid rgba(50, 50, 50, 0.6);
  margin-left: 1.5rem;
}

.autocomplete * {
  font: inherit;
}

.autocomplete > div {
  padding: 0.3rem;
}

.autocomplete .group {
  background: #efefef;
  font-style: italic;
}

.autocomplete > div:hover:not(.group),
.autocomplete > div.selected {
  background: #DEE5EA;
  cursor: pointer;
}

.autocomplete strong {
  font-weight: 700;
}
div.scrolly div.controls {
  width: 100%;
}
div.scrolly div.controls ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
div.scrolly div.controls ul li {
  width: 2rem;
  margin: 0 2rem;
}
div[data-container=story-3-1] div.controls {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}
div[data-container=story-3-2] div.controls {
  cursor: pointer;
}
.dropdown {
  position: relative;
}

.dropdown select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0.577rem 1.154rem 0.577rem 0.577rem;
  background-color: #fff;
  border: 1px solid #B0B0B0;
  border-radius: 80px;
  color: #000000;
  cursor: pointer;
  margin-right: 0.5rem;
}
.dropdown select:focus-visible {
  color: #082b7a;
  outline: 2px solid #0e47cb;
  outline-offset: 2px;
}
.dropdown select option {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

/*

*/
.dropdown::before,
.dropdown::after {
  --size__mark: 0.3rem;
  position: absolute;
  content: "";
  right: 1rem;
  pointer-events: none;
}

.dropdown::before {
  border-left: var(--size__mark) solid transparent;
  border-right: var(--size__mark) solid transparent;
  border-bottom: var(--size__mark) solid #B0B0B0;
  top: 40%;
}

.dropdown::after {
  border-left: var(--size__mark) solid transparent;
  border-right: var(--size__mark) solid transparent;
  border-top: var(--size__mark) solid #B0B0B0;
  top: 55%;
}
div.search {
  border-radius: 80px !important;
}

.search {
  position: relative;
  display: flex;
  border-radius: 80px;
}
.search:focus-within {
  color: #082b7a;
  outline: 2px solid #0e47cb;
  outline-offset: 2px;
  border-radius: 80px 0 0 80px;
}

.searchTerm {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid #B0B0B0;
  padding: 0.577rem 1.154rem;
  padding-right: 0;
  border-right: none;
  border-radius: 80px 0 0 80px;
  outline: none;
  color: #000000;
}

/*
.search:focus-within {
    color: #082b7a;
    border-radius: 2px;
    outline: 2px solid #0e47cb;
    outline-offset: 2px;
}
    */
.searchButton {
  width: 80px;
  border: 1px solid #B0B0B0;
  border-left: none;
  background: #ffffff;
  text-align: center;
  color: #000000;
  border-radius: 0 80px 80px 0;
  font-size: 20px;
  padding-right: 1.154rem;
  padding-left: 0.557rem;
  padding-top: 0.557rem;
  display: inline-block;
  fill: #B0B0B0;
}
.searchButton svg {
  height: 1.5rem;
}

.searchButton i {
  color: black;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
a.skip-link {
  background-color: #4073af;
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  color: #fff;
  display: block;
  font: normal normal 400 0.65rem/0.8125rem arial, sans-serif;
  height: 1px;
  margin: 0;
  outline: 2px solid #0e47cb;
  outline-offset: 0;
  overflow: hidden;
  padding: 0.325rem;
  position: absolute;
  text-decoration: underline;
  white-space: nowrap;
  width: 1px;
  z-index: 51;
  border-radius: 4px;
}
a.skip-link:visited {
  color: #d2bfdd;
}
.toggle {
  position: relative;
  width: 2.2rem;
  height: 1rem;
}
.toggle .rail {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ECECEC;
  border-radius: 1000px;
  transition: background ease-in-out 0.1s;
}
.toggle .rail:after {
  content: "";
  aspect-ratio: 1;
  height: 0.7rem;
  inset: 0.15rem;
  position: absolute;
  background: white;
  border-radius: 1000px;
  transition: all ease-in-out 0.2s;
}
.toggle input {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
  opacity: 0.00001;
  margin: 0;
}
.toggle input:checked + .rail {
  background: #2D6DEC;
}
.toggle input:checked + .rail:after {
  transform: translateX(calc(100% + 0.5rem));
}
.toggle input:focus-visible + .rail {
  color: #082b7a;
  outline: 2px solid #0e47cb;
  outline-offset: 2px;
}
/*# sourceMappingURL=style.css.map */
